<template>

  <v-card :loading="loaders.loading" flat>

    <v-row dense>
      <v-col v-if="user_id == idprofile && comicbookll.listEntity.length > 0"
             :key="'lnew'"
             class="d-flex child-flex v-alert--border"
             cols="12"
      >
        <v-card :to="'/comicbook/form'"
                rounded="lg" outlined
                width="100%"
                class="pa-3 text-center mx-auto"
        >
          <v-icon
            class="mb-1"
            color="secondary"
            size="60"
          >{{ icons.mdiPlus }}
          </v-icon>

          <h2 class="text-h5 ma-1">Nouvelle serie</h2>

        </v-card>
      </v-col>
      <v-col
        v-for="(item, index) in comicbookll.listEntity"
        :key="index"
        class="d-flex child-flex"
        cols="12"
      >
        <v-card
        >
          <div class="d-flex flex-no-wrap justify-start align-center">

            <v-avatar
              class="ma-3"
              size="125"
              rounded="0"
            ><v-img
              max-height="400"
              class="white--text align-end"
              aspect-ratio="1"
              :src="item.cover_src_sm"
              :lazy-src="require('@/assets/images/holder.jpg')"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 "
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            </v-avatar>
            <div style="width: 100%">
              <v-card-title class=" pb-3 d-flex justify-space-between">
                {{ item.name }}
                <v-spacer></v-spacer>

                <v-btn-toggle dense
                              variant="outlined"
                              divided
                >
                  <!--                  <v-btn small  tile="statistique" :to="'/comicbook/form/'+item.id">
                                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                                    </v-btn>-->

                  <v-btn small :to="'/comicbook/'+item.nameseo+'/'+item.id">
                    <v-icon>{{icons.mdiEyeOutline}}</v-icon> <span class="hidden-sm-only">Previsualiser</span>
                  </v-btn>
                  <v-menu
                    offset-y
                    left
                    min-width="150"
                    content-class="user-profile-menu-content"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small
                             v-bind="attrs"
                             v-on="on"
                      >
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <!--                  <v-btn small  tile="statistique" :to="'/comicbook/form/'+item.id">
                                            <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                                          </v-btn>-->
                        <v-btn small block  :to="'/comicbook/form/'+item.id">
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon> Modifier
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn small block  @click="deleteChap(item, index)">
                          <v-icon>{{ icons.mdiClose }}</v-icon> Supprimer
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn-toggle>
              </v-card-title>

              <v-card-subtitle>
              </v-card-subtitle>

              <v-card-actions>
                  <v-btn small color="primary"  :to="'/comicbook/new-chapter/'+item.id">
                    <v-icon>{{ icons.mdiPlus }}</v-icon> Nouveau chapitre
                  </v-btn>
                  <v-btn small   :to="'/creator/comicbook/'+item.id">
                    <v-icon>{{ icons.mdiReorderHorizontal }}</v-icon> Liste
                  </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>

      </v-col>
    </v-row>

    <v-sheet
      v-if="loaders.loading"
      class="mx-auto"
    >
      <v-row dense>
        <v-col
          v-for="n in 3"
          :key="'lnew'+n"
          class="d-flex child-flex"
          cols="12"
        >
          <v-card
            :color="`grey darken-2 `"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              :height="120"
              :width="120"
              type="card"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>

    <!--
        <v-card v-intersect="infiniteScrolling"></v-card>-->

    <v-sheet v-if="!loaders.loading && comicbookll.listEntity.length === 0"
             elevation="0"
             max-width="600"
             rounded="lg"
             width="100%"
             class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="warning"
        size="112"
      >{{ icons.mdiExclamation }}
      </v-icon>

      <h2 class="text-h5 mb-6">Aucun element trouve</h2>

      <template v-if="user_id == idprofile">
        <p class="mb-4 text-medium-emphasis text-body-2">
          Vous pouvez creer vos propre series et one-shot
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-center">
          <v-btn
            class="text-none"
            color="success"
            rounded
            variant="flat" :to="'/comicbook/form'"
          >
            Ajouter une Serie
          </v-btn>
        </div>
      </template>

    </v-sheet>

    <template v-if="user_id == idprofile">

      <v-dialog fullscreen v-model="dialog.newcomicbook">

      </v-dialog>

    </template>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiExclamation,
  mdiPlus,
  mdiReorderHorizontal,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfileItem from "@/components/ProfileItem.vue";
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
import ChapterImageFormController from "@/components/ChapterImageFormController.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";

export default {
  components: {
    ImageUploadController,
    ChapterImageFormController,
    ChapterFormComponent,
    ProfileItem,
    FollowToggleComponent,
  },
  props: {
    comicbookoption: Object,
    idprofile: Number|String,
    user_id: Number|String,
    user: Object,
  },
  setup(props) {
    const dialog = ref({
      newcomicbook: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })

    const comicbookll = ref({listEntity: []})
    const loadcomicbook = next => {

      loaders.value.loading = true
      loaders.value.retry = false


      Drequest.api('lazyloading.comicbook?dfilters=on&dsort=id desc')
        .param({
          'user.id:eq': props.idprofile,
          per_page: 12,
          next,
          user_id: props.user_id,
          ...props.comicbookoption,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {

            comicbookll.value.listEntity = [...comicbookll.value.listEntity, ...response.listEntity]
            comicbookll.value.next = response.next

          } else {
            loaders.value.loading = false
            comicbookll.value = response

          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }
    loadcomicbook(1)

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)

      if (loaders.value.loading) return

      console.log(comicbookll.value.next >= 2, comicbookll.value)
      // comicbookll.value.next >= 2 &&
      if (comicbookll.value.remain) loadcomicbook(comicbookll.value.next)

    }

    const deleteChap = (comicbook, index) => {
      console.log(loaders.value.loading)

      if (!confirm('Confirmer la suppression de ce One shot?.'))
        return 0;

      Drequest.api('comicbook.delete?id=' + comicbook.id)
        .get(response => {
          console.log(response)
          comicbookll.value.listEntity.splice(index, 1)
        })

    }

    return {
      infiniteScrolling,
      deleteChap,

      comicbookll,
      loaders,
      dialog,

      icons: {
        mdiClose,
        mdiExclamation,
        mdiDotsVertical,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
        mdiPlus,
        mdiReorderHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 120 !important;
}

/*.v-dialog__content {
  z-index: 4001 !important;
}*/
</style>
