<template>
  <div class=" ">
    <h3>Profile Createur</h3>
      <v-tabs class=" font-secondary"
          v-model="tab" center-active
      align-tabs="center"
              grow
        >
          <v-tab
            key="illustrations"
          >
            Mes illustrations
          </v-tab>
          <v-tab
            key="series"
          >
            Mes Series
          </v-tab>
          <v-tab
            key="oneshot"
          >
            Mes One-Shots
          </v-tab>
        </v-tabs>

      <template v-if="profile.id">

        <v-tabs-items v-model="tab">
          <v-tab-item
            key="illustrations"
            style="min-height: 500px"
          >
          <profile-post-component
            :postoption="{ 'status._key:eq': 'published', }"
            :user="user"
            :idprofile="idprofile"
            :user_id="user_id"
          ></profile-post-component>

          </v-tab-item>
          <v-tab-item
            :key="'series'"
            style="min-height: 500px"
          >
            <creator-series-component
              :user="user"
              :idprofile="idprofile"
              :user_id="user_id"
            ></creator-series-component>

          </v-tab-item>
          <v-tab-item
            :key="'oneshot'"
            style="min-height: 500px"
          >
          <profile-creator-component
            :user="user"
            :idprofile="idprofile"
            :user_id="user_id"
          ></profile-creator-component>

          </v-tab-item>

        </v-tabs-items>
      </template>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
mdiLogout,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import LoginComponent from '@/components/LoginComponent'
import { Drequest } from '@/plugins/Drequest'
import ValidateAccountComponent from '@/components/ValidateAccountComponent'
import RegisterComponent from '@/components/RegisterComponent'
import PostMinifiedComponent from '@/components/PostMinifiedComponent'
import PostFormComponent from '@/components/PostFormComponent'
import { useRouter } from '@/utils'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfilePostComponent from '@/components/ProfilePostComponent.vue'
import ProfileContestComponent from "@/components/ProfileContestComponent.vue";
import ProfileCreatorComponent from "@/components/ProfileCreatorComponent.vue";
import CreatorSeriesComponent from "@/components/creator/CreatorSeriesComponent.vue";

export default {
  components: {
    CreatorSeriesComponent,
    ProfileCreatorComponent,
    ProfileContestComponent,
    FollowToggleComponent,
    PostMinifiedComponent,
    ProfilePostComponent
},
  setup() {
    const dialog = ref({
      newpost: false,
      imageviewer: false,
      authentication: false,
    })
    const { router } = useRouter()
    // const route = router.currentRoute
    const user_id = ref((localStorage.getItem('user_id')))
    const idprofile = user_id.value
    const user = ref(Drequest.getUser())
    const subscription = ref({})
    const profile = ref({})
    const tab = ref(0)
    const loaders = ref({
      config: false,
      loading: false,
      loadingseries: false,
      loadingchalleng: false,
      retry: false,
    })
    const configurations = ref([])
    const newaccount = ref(false)
    const changeavatar = ref(false)

    const postdetail = ref({})
    const postll = ref({})
    const postseriesll = ref({})
    const postchallengll = ref({})
    const postoption = ref({ 'status._key:eq': 'published', })

    const logout = () => {
      Drequest.logout()
      window.location.href = '/settings'
    }

    Drequest.api('detail.user?id=' + idprofile)
      .param({
        user_id: user_id.value
      })
      .get(response => {
        console.log(response)
        if(response.user.id)
          profile.value = response.user
        else
          logout()

      })
      .fail(e => {
        console.log(e)
      })

    if (user_id.value) {
      //todo: relationship
    }

    const logged = (user, message) => {
      console.log(user, message)
      window.location.reload()
    }
    const followcallback = (response) => {
      console.log(response)
      window.location.reload()
      // profile.value = { ...profile.value, ...response }
    }

    return {
      logout,
      logged,
      followcallback,

      profile,
      postdetail,
      tab,
      dialog,
      postll,
      changeavatar,
      newaccount,
      loaders,
      configurations,
      subscription,
      user,
      user_id,
      idprofile,

      icons: {
        mdiClose,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
